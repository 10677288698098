<template>
  <div class="join center">
    <div class="baseBox">
      <div class="joinBox">
        <router-link to="/" class="logo">
          <img src="@/assets/images/logo.png" alt="logo" />
        </router-link>
        <h2>Find Password</h2>
        <div class="form">
          <!-- <div class="flex">
            <p>Email <span>*</span></p>
            <input type="text" v-model="email" />
          </div> -->
          <div class="flex">
            <p>
              Verify email
              <span>*</span>
            </p>
            <div class="cert">
              <input type="text" v-model="email" :disabled="isVerify == true" />
              <button class="point" @click="requestCertCode">get code</button>
            </div>
          </div>
          <div class="flex" v-show="isRequestCertCode">
            <p>
              Vefication code
              <span>*</span>
            </p>
            <div class="cert">
              <input
                class="tel"
                type="text"
                v-model="certCode"
                placeholder="Please enter the verification code."
              />
              <button class="pointOut" @click="verifyCode">confirm code</button>
            </div>
          </div>
        </div>
        <!-- <div v-if="error" class="error">
          해당 정보로 가입한 회원정보를 찾을 수 없습니다.
        </div> -->
        <div class="buttonWrap">
          <button class="point large" @click="submit">Find Password</button>
          <!-- <router-link to="/login">
            <button class="cancel large pointOut">Cancel</button>
          </router-link> -->
          <a href="/login">
            <button class="cancel large pointOut">Cancel</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import {
  // findPwd,
  verifyEmail,
  createCertCode,
} from "@/api/index";
export default {
  mixins: [format],
  data() {
    return {
      email: "",
      isRequestCertCode: false,
      certCode: "",
      isVerify: false,
      verifiedEmail: "",
    };
  },
  methods: {
    // removeChar(event) {
    //   if (
    //     event.keyCode == 8 ||
    //     event.keyCode == 46 ||
    //     event.keyCode == 37 ||
    //     event.keyCode == 39
    //   )
    //     return;
    //   else event.target.value = event.target.value.replace(/[^0-9]/g, "");
    // },
    requestCertCode() {
      if (this.email == "") {
        return alert("Enter your email address.");
      } else if (this.emailValidate(this.email) == false) {
        return alert("Please enter your valid and entire email address.");
      }
      let data = {
        email: this.email,
        findPassword: true,
      };
      createCertCode(data).then((res) => {
        if (res.data.status == 200) {
          this.isRequestCertCode = true;
          return alert(
            "Please enter the verification code that was sent to the email address registered for your account."
          );
        } else {
          alert(res.data.message);
        }
      });
    },
    verifyCode() {
      if (this.certCode == "") {
        return alert("Please enter the verification code.");
      }
      let data = {
        email: this.email,
        certCode: this.certCode,
      };
      verifyEmail(data).then((res) => {
        if (res.data.status == 200) {
          this.isVerify = true;
          this.isRequestCertCode = false;
          this.verifiedEmail = this.email;
          return alert("Verification Successful.");
        } else {
          this.isVerify = false;
          return alert(res.data.message);
        }
      });
    },
    submit() {
      if (this.isVerify == false) {
        return alert("Please verify your email address.");
      } else if (this.email != this.verifiedEmail) {
        return alert("Email address is not verified.");
      } else {
        this.$router.push({
          name: "changePassword",
          params: { email: this.email },
        });
      }
      // let data = {
      //   email: this.email,
      // };
      // findPwd(data).then((res) => {
      //   if (res.data.status == 200) {
      //     this.$store.dispatch("SET_USER_INFO", data);
      //     this.$router.push("/changePassword");
      //   } else {
      //     let message = res.data.message;
      //     alert(message);
      //   }
      // });
    },
  },
};
</script>
